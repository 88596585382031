import { FunctionComponent } from "react";

import { StrapiLottieDynamicZone, StrapiPrimitiveMedia } from "@/types/strapi";

import { LottieAnimation } from "@/ui/atoms/lottie_animation";
import { Media } from "@/ui/atoms/media";
import { LegacyMediaCarousel } from "@/ui/organisms/legacy_media_carousel";

interface LegacyFeatureMediaProps {
    Lottie_File?: StrapiLottieDynamicZone;
    Media?: StrapiPrimitiveMedia[];
}

export const LegacyFeatureMedia: FunctionComponent<LegacyFeatureMediaProps> = (
    props,
) => {
    if (props.Lottie_File) {
        return <LottieAnimation path={props.Lottie_File.Lottie_File.url} />;
    }

    if (props.Media && props.Media.length > 1) {
        return <LegacyMediaCarousel {...{ Media: props.Media }} />;
    }

    if (props.Media && props.Media.length === 1) {
        return <Media {...props.Media[0]} />;
    }

    return null;
};
