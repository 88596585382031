/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FocusEventHandler, FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

import { HubSpotField } from "@/types/forms";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { FontStyleSlug } from "@/tokens/configs/typography_config";
import { FontFamilies } from "@/tokens/fonts";
import { Spacing } from "@/tokens/spacing";

import { Text } from "@/ui/atoms/text";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import {
    generateFontStyles,
    getBaseTypeStyles,
} from "@/util/tokens/typography_util";
import { convertToRem } from "@/util/ui_util";

interface TextInputProps extends HubSpotField {
    className?: SerializedStyles;
    errorMessage?: string;
    fontSize?: FontStyleSlug;
    onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    register: UseFormRegister<Record<string, string>>;
    value?: string;
}

export const SelectInput: FunctionComponent<TextInputProps> = ({
    fontSize = "TextDefault",
    register,
    ...props
}) => {
    const theme = useTypedTheme();

    /**
     * Styles
     */
    const baseInputStyles = css(
        {
            appearance: "none",
            backgroundAttachment: "scroll",
            backgroundColor: Colors[theme.backgrounds.backgroundSecondary],
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 256 256'%3E%3Cpath fill='rgba(255,255,255,0.8)' d='m213.66 101.66-80 80a8 8 0 0 1-11.32 0l-80-80a8 8 0 0 1 11.32-11.32L128 164.69l74.34-74.35a8 8 0 0 1 11.32 11.32'/%3E%3C/svg%3E")`,
            backgroundPosition: "right 0.5rem center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "1em 1em",
            border: `1px solid ${props.errorMessage ? Colors["primary-red"] : "transparent"}`,
            borderRadius: convertToRem(BorderRadiuses.borderSmall),
            boxSizing: "border-box",
            padding: `${Spacing["spacing-3"]} ${Spacing["spacing-2"]}`,
            width: "100%",
        },

        generateFontStyles(fontSize, {}),
        getBaseTypeStyles(theme.text.textPrimary),
        FontFamilies["sans"].style,
    );

    const inputStyles = css(baseInputStyles, props.className);

    const inputLabelGroupStyles = css({
        display: "block",
        marginBottom: Spacing["spacing-6"],
    });

    /**
     * Rendering
     */
    return (
        <label css={inputLabelGroupStyles}>
            <Text marginBottom="InputLabelMargin">
                {props.label}

                {props.required && (
                    <Text color={theme.text.headlineSecondary} tag="span">
                        *
                    </Text>
                )}
            </Text>

            <select
                css={inputStyles}
                value={props.value}
                {...register(props.name, {
                    required: props.required && "Please select an option.",
                })}
            >
                <option value="">Select one...</option>

                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            {props.errorMessage && (
                <Text color="primary-red" marginTop="InputLabelMargin">
                    {props.errorMessage}
                </Text>
            )}
        </label>
    );
};
