/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyFeatureItem } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { Icon } from "@/ui/atoms/icon";
import { Link } from "@/ui/atoms/link";
import { Text } from "@/ui/atoms/text";
import { LegacyFeatureMedia } from "@/ui/molecules/legacy_feature_media";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface VerticalLegacyFeatureItemProps extends StrapiLegacyFeatureItem {
    className?: SerializedStyles;
}

export const VerticalLegacyFeatureItem: FunctionComponent<
    VerticalLegacyFeatureItemProps
> = (props) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeatureVerticalItem),
        props.className,
    );

    const verticalFeatureTextContainerStyles = css(
        {
            display: "flex",
            flex: "1 0 0",
            flexDirection: "column",
        },
        buildStylesByBreakpoint(
            "gap",
            spacingSets.LegacyFeatureVerticalItemTextContainer,
        ),
    );

    const verticalFeatureTextStyles = css(
        {
            display: "flex",
            flex: "1 0 0",
            flexDirection: "column",
        },
        buildStylesByBreakpoint(
            "gap",
            spacingSets.LegacyFeatureVerticalItemText,
        ),
    );

    const verticalFeatureTextBaseStyles = buildStylesByBreakpoint("maxWidth", {
        extraSmall: "100%",
        small: "55ch",
        large: "65ch",
    });

    const iconStyles = css(
        {
            display: "flex",
            gap: Spacing["spacing-2"],
        },
        buildStylesByBreakpoint("alignItems", {
            extraSmall: "flex-start",
            medium: "center",
        }),
    );

    /**
     * Rendering
     */
    const renderHeadline = () => {
        const headline = (
            <Text
                className={verticalFeatureTextBaseStyles}
                fontSize="LegacyFeatureVerticalItemHeadline"
                fontWeight="medium"
                themeKey="headlinePrimary"
            >
                {props.Headline}
            </Text>
        );

        if (props.Icon) {
            return (
                <div css={iconStyles}>
                    <Icon
                        color="green-20"
                        size={{
                            extraSmall: 20,
                            small: 24,
                            large: 32,
                        }}
                        slug={props.Icon}
                        weight="light"
                    />

                    {headline}
                </div>
            );
        }

        return headline;
    };

    return (
        <div css={containerStyles}>
            <div css={verticalFeatureTextContainerStyles}>
                <div css={verticalFeatureTextStyles}>
                    {props.Eyebrow && (
                        <Flag fontSize="LegacyFeatureVerticalItemEyebrow">
                            {props.Eyebrow}
                        </Flag>
                    )}

                    {props.Headline && renderHeadline()}

                    {props.Subheadline && (
                        <RichText
                            className={verticalFeatureTextBaseStyles}
                            config="LegacyFeatureVerticalItem"
                        >
                            {props.Subheadline}
                        </RichText>
                    )}
                </div>

                {props.Link && (
                    <Link
                        fontSize="LegacyFeatureVerticalItemLink"
                        href={props.Link.URL}
                    >
                        {props.Link.Text}
                    </Link>
                )}
            </div>

            <LegacyFeatureMedia
                {...{
                    Lottie_File: props.Lottie_File,
                    Media: props.Media,
                }}
            />
        </div>
    );
};
