/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import * as _ from "lodash-es";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import { StrapiPrimitiveMedia } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Media } from "@/ui/atoms/media";
import { Text } from "@/ui/atoms/text";

import { useLegacyMediaCarouselAnimation } from "@/util/animation_hooks/legacy_media_carousel_animations";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { convertToRem } from "@/util/ui_util";

interface LegacyMediaCarouselProps {
    className?: SerializedStyles;
    Media?: StrapiPrimitiveMedia[];
}

export const LegacyMediaCarousel: FunctionComponent<
    LegacyMediaCarouselProps
> = (props) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();

    /**
     * State
     */
    const [currentMediaCarouselIndex, setCurrentMediaCarouselIndex] =
        useState(0);

    /**
     * Refs
     */
    const mediaContainerRef = useRef<HTMLDivElement>(null);
    const captionsContainerRef = useRef<HTMLUListElement>(null);
    const [carouselButtonRefs, setCarouselButtonRef] = useArrayRef();

    /**
     * Hooks
     */
    useLegacyMediaCarouselAnimation(
        mediaContainerRef,
        captionsContainerRef,
        carouselButtonRefs,
        props.Media ? props.Media.length : 0,
        currentMediaCarouselIndex,
    );

    /**
     * Interactivity
     */
    const handleClick = (index: number) => {
        setCurrentMediaCarouselIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentMediaCarouselIndex(
                (prevIndex) => (prevIndex + 1) % props.Media!.length,
            );
        }, 5000);

        return () => clearInterval(interval);
    }, [props.Media, currentMediaCarouselIndex]);

    /**
     * Util
     */
    const hasCaptions =
        props.Media &&
        _.every(
            props.Media,
            (_media) =>
                _.has(_media, "Caption") && !_.isUndefined(_media.Caption),
        );

    /**
     * Styles
     */
    const carouselContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            position: "relative",
            rowGap: Spacing["spacing-4"],
        },

        props.className,
    );

    const buttonsContainerStyles = css({
        backdropFilter: "blur(16px)",
        background: Colors[theme.backgrounds.backgroundSecondary],
        borderRadius: BorderRadiuses.borderRound,
        bottom: Spacing["spacing-2"],
        display: "flex",
        gap: Spacing["spacing-1-half"],
        left: "50%",
        padding: Spacing["spacing-2"],
        position: "absolute",
        transform: "translateX(-50%)",
        zIndex: 1,
    });

    const buttonStyles = css(
        {
            appearance: "none",
            backgroundColor: Colors["lighten-60"],
            border: "none",
            borderRadius: BorderRadiuses.borderRound,
            cursor: "pointer",
            display: "block",
            height: convertToRem(10),
            padding: 0,
            width: convertToRem(10),
        },
        {
            "&:hover, &:focus, &:active": {
                outline: "none",
            },
        },
    );

    const mediaContainerStyles = css({
        borderRadius: BorderRadiuses.borderLarge,
        overflow: "hidden",
        position: "relative",
    });

    /**
     * Rendering
     */
    const renderButtons = () => {
        return (
            <div css={buttonsContainerStyles}>
                {props.Media!.map((_, index) => (
                    <button
                        aria-label={`Go to media ${index + 1}`}
                        css={buttonStyles}
                        key={index}
                        ref={setCarouselButtonRef}
                        onClick={() => handleClick(index)}
                    />
                ))}
            </div>
        );
    };

    const renderMedia = () => {
        const media = props.Media!;

        return (
            <div css={mediaContainerStyles}>
                <div
                    css={css({
                        alignItems: "flex-start",
                        display: "flex",

                        width: `calc(${media.length} * 100%)`,
                    })}
                    ref={mediaContainerRef}
                >
                    {media.map((item) => (
                        <Media
                            {...item}
                            borderRadius="borderNone"
                            className={css({
                                flex: "1 0 0",
                            })}
                            key={`media-carousel::media-object::${item.id}`}
                        />
                    ))}
                </div>

                {renderButtons()}
            </div>
        );
    };

    const renderCaptions = () => {
        const media = props.Media!;

        return (
            <ul
                css={css({
                    alignItems: "flex-start",
                    display: "flex",
                    overflow: "hidden",
                    width: `calc(${media.length} * 100%)`,
                })}
                ref={captionsContainerRef}
            >
                {media.map((item) => (
                    <Text
                        className={css({
                            flex: "1 0 0",
                        })}
                        fontSize="Small"
                        key={`media-carousel::caption::${item.id}`}
                        tag="li"
                    >
                        {item.Caption}
                    </Text>
                ))}
            </ul>
        );
    };

    return (
        <div css={carouselContainerStyles}>
            {props.Media && renderMedia()}

            {hasCaptions && renderCaptions()}
        </div>
    );
};
