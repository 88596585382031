/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useGSAP } from "@gsap/react";
import FocusTrap from "focus-trap-react";
import { gsap } from "gsap";
import {
    MouseEventHandler,
    ReactNode,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { createPortal } from "react-dom";

import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Icon } from "@/ui/atoms/icon";

interface ModalProps {
    children: ReactNode;
    onClose: () => void;
}

export function Modal(props: ModalProps) {
    /**
     * Globals & Refs
     */
    const rootRef = useRef<HTMLDivElement>(null);

    /**
     * Styles
     */
    const overlayStyles = css({
        alignItems: "center",
        backgroundColor: Colors["darken-90"],
        display: "flex",
        inset: 0,
        justifyContent: "center",
        opacity: 0,
        position: "fixed",
        zIndex: 1000,
    });

    const closeButtonStyles = css(
        {
            appearance: "none",
            background: "transparent",
            border: "none",
            color: Colors["lighten-80"],
            cursor: "pointer",
            padding: 0,
            position: "absolute",
            right: Spacing["spacing-4"],
            top: Spacing["spacing-4"],
        },
        {
            "&:focus-visible": {
                color: Colors["white"],
            },
            "&:hover": {
                color: Colors["white"],
            },
        },
    );

    /**
     * Animations
     */
    const { contextSafe } = useGSAP(() => {
        gsap.to(rootRef.current, {
            duration: 0.2,
            opacity: 1,
        });
    });

    /**
     * Interactions
     * https://gsap.com/resources/React/#making-your-animation-context-safe
     */
    const handleClose = useMemo(
        () =>
            contextSafe(() => {
                gsap.to(rootRef.current, {
                    duration: 0.2,
                    onComplete: props.onClose,
                    opacity: 0,
                });
            }),
        [contextSafe, props.onClose],
    );

    const handleOverlayClick: MouseEventHandler<HTMLDivElement> = (event) => {
        event.stopPropagation();
        /**
         * Check if the clicked element is the overlay (current target)
         * and not modal content
         */
        if (event.target === event.currentTarget) {
            handleClose();
        }
    };

    /**
     * Prevent Scrolling
     */
    useEffect(() => {
        const original = document.body.style.overflow;
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = original;
        };
    }, []);

    /**
     * Handle keydown
     */
    useEffect(() => {
        function handleKeydown(event: KeyboardEvent) {
            if (event.key === "Escape") {
                handleClose();
            }
        }
        document.addEventListener("keydown", handleKeydown);
        return () => {
            document.removeEventListener("keydown", handleKeydown);
        };
    }, [handleClose]);

    /**
     * Rendering
     */
    return createPortal(
        <FocusTrap>
            <div
                aria-hidden
                css={overlayStyles}
                ref={rootRef}
                onClick={handleOverlayClick}
            >
                <button
                    css={closeButtonStyles}
                    type="button"
                    onClick={handleClose}
                >
                    <Icon renderContainer size={28} slug="Close" />
                </button>

                {props.children}
            </div>
        </FocusTrap>,
        document.body,
    );
}
