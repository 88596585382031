/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiTestimonial } from "@/types/strapi";

import { BorderRadiuses } from "@/tokens/border";
import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Picture } from "@/ui/atoms/picture";
import { Text } from "@/ui/atoms/text";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface LegacyTestimonialProps extends StrapiTestimonial {
    className?: SerializedStyles;
}

export const LegacyTestimonial: FunctionComponent<LegacyTestimonialProps> = ({
    ...props
}) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "flex-start",
        },
        buildStylesByBreakpoint(
            "rowGap",
            spacingSets.LegacyTestimonialHeadshot,
        ),
    );

    const headshotStyles = css(
        { borderRadius: BorderRadiuses.borderLarge },
        buildStylesByBreakpoint("width", {
            extraSmall: Spacing["spacing-18"],
            medium: "100%",
        }),
    );

    const quoteContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.LegacyTestimonialItems),
    );

    /**
     * Rendering
     */
    const renderAttribution = () => {
        const { Company, Name, Role } = props.Author!;

        return (
            <div>
                <Text
                    fontSize="LegacyTestimonialAttribution"
                    fontWeight="medium"
                    marginBottom="LegacyTestimonialAttribution"
                >
                    {Name}
                </Text>

                <Text
                    fontSize="LegacyTestimonialAttribution"
                    themeKey="textSecondary"
                >
                    {Company ? `${Role}, ${Company.Name}` : Role}
                </Text>
            </div>
        );
    };

    const renderQuote = () => {
        return (
            <GridColumn
                className={quoteContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: props.Author ? 9 : 8,
                    large: props.Author ? 7 : 6,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                    large: 4,
                }}
            >
                <Text
                    fontSize="LegacyTestimonialQuote"
                    tag="blockquote"
                    themeKey="headlinePrimary"
                >{`“${props.Quote}”`}</Text>

                {props.Author && renderAttribution()}
            </GridColumn>
        );
    };

    const renderHeadshot = () => {
        const Author = props.Author!;

        return (
            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 1,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 2,
                    large: 3,
                }}
            >
                <Picture
                    alternativeText={`Headshot of ${Author.Name}`}
                    className={headshotStyles}
                    height={Author.Headshot.height}
                    url={Author.Headshot.url}
                    width={Author.Headshot.width}
                />
            </GridColumn>
        );
    };

    return (
        <GridContainer className={containerStyles}>
            {props.Author && props.Author.Headshot && renderHeadshot()}

            {renderQuote()}
        </GridContainer>
    );
};
