/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyFeatureItem } from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { BorderRadiuses } from "@/tokens/border";
import { spacingSets } from "@/tokens/configs/spacing_config";

import { Button } from "@/ui/atoms/button";
import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { LegacyFeatureMedia } from "@/ui/molecules/legacy_feature_media";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";

type HorizontalLegacyFeatureOrientation = "default" | "reversed";

interface HorizontalLegacyFeatureConfig {
    columnSpan: GridColumnSpan;
    columnStart: GridColumnCount;
}

interface HorizontalLegacyFeatureProps extends StrapiLegacyFeatureItem {
    className?: SerializedStyles;
    orientation?: HorizontalLegacyFeatureOrientation;
    renderContainer?: boolean;
}

export const HorizontalLegacyFeature: FunctionComponent<
    HorizontalLegacyFeatureProps
> = ({ orientation = "default", renderContainer = false, ...props }) => {
    /**
     * Styles
     */
    const textContainerStyles = css(
        {
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("order", {
            extraSmall: 1,
            medium: orientation === "default" ? 1 : 2,
        }),
    );

    const mediaContainerStyles = css(
        {
            borderRadius: BorderRadiuses.borderLarge,
            overflow: "hidden",
        },
        buildStylesByBreakpoint("order", {
            extraSmall: 1,
            medium: orientation === "default" ? 2 : 1,
        }),
    );

    /**
     * Rendering
     */
    const getTextContainerGridConfig = (): HorizontalLegacyFeatureConfig => {
        return {
            columnSpan: {
                extraSmall: "full",
                medium: 5,
                large: 4,
            },
            columnStart:
                orientation === "default"
                    ? {
                          extraSmall: 1,
                          large: 2,
                      }
                    : {
                          extraSmall: 1,
                          medium: 8,
                      },
        };
    };

    const getMediaContainerGridConfig = (): HorizontalLegacyFeatureConfig => {
        return {
            columnSpan: {
                extraSmall: "full",
                medium: 6,
            },
            columnStart:
                orientation === "default"
                    ? {
                          extraSmall: 1,
                          medium: 7,
                      }
                    : 1,
        };
    };

    const renderLegacyFeature = () => (
        <SubgridContainer>
            <GridColumn
                {...getTextContainerGridConfig()}
                className={textContainerStyles}
                marginBottom="LegacyFeatureHorizontalItem"
            >
                {props.Eyebrow && (
                    <Flag
                        className={buildStylesByBreakpoint(
                            "marginBottom",
                            spacingSets.LegacyFeatureHorizontalItemEyebrow,
                        )}
                        fontSize="LabelLarge"
                    >
                        {props.Eyebrow}
                    </Flag>
                )}

                {props.Headline && (
                    <Text
                        fontSize="LegacyFeatureHorizontalItemHeadline"
                        marginBottom="LegacyFeatureHorizontalItemHeadline"
                        tag="h2"
                    >
                        {props.Headline}
                    </Text>
                )}

                {props.Subheadline && (
                    <RichText config="Longform">{props.Subheadline}</RichText>
                )}

                {props.Link && (
                    <Button
                        fontSize="LegacyFeatureHorizontalItemLink"
                        href={props.Link.URL}
                        marginTop="LegacyFeatureHorizontalItemLink"
                        variant="secondary"
                    >
                        {props.Link.Text}
                    </Button>
                )}
            </GridColumn>

            <GridColumn
                {...getMediaContainerGridConfig()}
                className={mediaContainerStyles}
            >
                <LegacyFeatureMedia
                    {...{
                        Lottie_File: props.Lottie_File,
                        Media: props.Media,
                    }}
                />
            </GridColumn>
        </SubgridContainer>
    );

    return renderContainer ? (
        <GridContainer>{renderLegacyFeature()}</GridContainer>
    ) : (
        renderLegacyFeature()
    );
};
