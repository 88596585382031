import { FunctionComponent } from "react";

import { HubSpotForm } from "@/types/forms";
import { StrapiContactFormPage } from "@/types/strapi";

import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Text } from "@/ui/atoms/text";
import { ContactForm } from "@/ui/molecules/contact_form";
import { RichText } from "@/ui/molecules/rich_text";

interface ContactFormPageTemplateProps extends StrapiContactFormPage {
    formData: HubSpotForm | null;
}

export const ContactFormPageTemplate: FunctionComponent<
    ContactFormPageTemplateProps
> = (props) => {
    return (
        <GridContainer
            marginBottom="Default"
            marginTop="LongformHeadlineDefault"
        >
            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                }}
                columnStart={{
                    extraSmall: 1,
                }}
                marginBottom="Default"
            >
                <Text
                    columnSpan="full"
                    columnStart={1}
                    fontSize="HeadlineDefault"
                    marginBottom="Default"
                    tag="h1"
                >
                    {props.Headline}
                </Text>

                {props.Subheadline && (
                    <RichText config="Longform">{props.Subheadline}</RichText>
                )}
            </GridColumn>

            {props.formData && (
                <GridColumn
                    columnSpan={{
                        extraSmall: "full",
                        medium: 6,
                    }}
                    columnStart={{
                        extraSmall: 1,
                        medium: 7,
                    }}
                >
                    {props.Slug && (
                        <ContactForm
                            formId={props.formData.guid}
                            inputs={props.formData.formFieldGroups}
                            portalId={props.formData.portalId.toString()}
                        />
                    )}
                </GridColumn>
            )}
        </GridContainer>
    );
};
