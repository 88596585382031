/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyHero } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { CtaGroup } from "@/ui/molecules/cta_group";
import { ProductAnimation } from "@/ui/molecules/product_animation";
import { WatchDemo } from "@/ui/molecules/watch_demo";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface ProductHeroProps extends StrapiLegacyHero {
    className?: SerializedStyles;
    onlyRenderDownloadCta?: boolean;
}

export const ProductHero: FunctionComponent<ProductHeroProps> = (props) => {
    /**
     * State
     */
    const shouldRenderLottieCarousel = props?.Media?.Lottie_Files?.length
        ? props.Media.Lottie_Files.length > 1
        : false;

    /**
     * Styles
     */
    const containerStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.ProductHeroBlocks),
    );

    const textContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.ProductHeroText),
        buildStylesByBreakpoint("alignItems", {
            extraSmall: "center",
            large: "flex-start",
        }),
    );

    const productAnimationStyles = buildStylesByBreakpoint("padding", {
        extraSmall: Spacing["spacing-8"],
        medium: `${Spacing["spacing-8"]} ${Spacing["spacing-0"]}`,
        large: shouldRenderLottieCarousel
            ? convertToRem(36)
            : Spacing["spacing-16"],
    });

    const watchDemoStyles = css({
        marginTop: Spacing["spacing-12"],
    });

    /**
     * Rendering
     */
    const renderTextContainer = () => {
        return (
            <GridColumn
                className={textContainerStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                    large: 4,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                    large: 1,
                }}
            >
                {props.Eyebrow && (
                    <Flag fontSize="LabelLarge" tag="div">
                        {props.Eyebrow}
                    </Flag>
                )}

                <Text
                    fontSize="HeadlineDefault"
                    tag="h1"
                    textAlign={{
                        extraSmall: "center",
                        large: "left",
                    }}
                >
                    {props.Headline}
                </Text>

                <Text
                    fontSize="ProductHeroSubheadline"
                    shouldBalanceWrap={true}
                    textAlign={{
                        extraSmall: "center",
                        large: "left",
                    }}
                    themeKey="textPrimary"
                >
                    {props.Subheadline}
                </Text>
            </GridColumn>
        );
    };

    const renderCtaGroup = () => {
        const _castedCta = props.CTA!;

        return (
            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                    large: 4,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 4,
                    large: 1,
                }}
                marginTop="ProductHeroCta"
            >
                <CtaGroup
                    alignment={{ extraSmall: "center", large: "start" }}
                    ctaData={_castedCta}
                    onlyRenderDownloadCta={props.onlyRenderDownloadCta}
                    primaryFontSize="CtaHero"
                    secondaryFontSize="Micro"
                />
            </GridColumn>
        );
    };

    return (
        <section>
            <GridContainer
                className={containerStyles}
                marginTop="DefaultSectionMargin"
            >
                <SubgridContainer
                    columnSpan={{
                        extraSmall: "full",
                        large: 5,
                    }}
                >
                    {renderTextContainer()}

                    {props.CTA && renderCtaGroup()}
                </SubgridContainer>

                {/**
                 * props.Media is optional for other
                 * non-product pages that need the text structure
                 * but not Media structure.
                 */}
                {props.Media && (
                    <ProductAnimation
                        {...props.Media}
                        animationColumnSpan={{
                            extraSmall: "full",
                            medium: 8,
                            large: "full",
                        }}
                        animationColumnStart={{
                            extraSmall: 1,
                            medium: 3,
                            large: 1,
                        }}
                        className={productAnimationStyles}
                        containerColumnSpan="full"
                        containerColumnStart={{
                            extraSmall: 1,
                            large: 6,
                        }}
                        placeholderAspectRatio={
                            shouldRenderLottieCarousel ? undefined : "3 / 2"
                        }
                    />
                )}
            </GridContainer>

            {props.Watch_Demo && (
                <GridContainer>
                    <GridColumn>
                        <WatchDemo className={watchDemoStyles} />
                    </GridColumn>
                </GridContainer>
            )}

            {!props.Watch_Demo && <Divider marginTop="DefaultSectionMargin" />}
        </section>
    );
};
