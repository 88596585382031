import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiGeneralTextPage } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";

import { formatDate } from "@/util/date_util";

export const GeneralTextPageTemplate: FunctionComponent<
    StrapiGeneralTextPage
> = (props) => {
    /**
     * Styles
     */

    const headlineGroupStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const eyebrownStyles = css({
        marginBottom: Spacing["spacing-2"],
    });

    return (
        <GridContainer
            marginBottom="LongformPageContainer"
            marginTop="LongformPageContainer"
        >
            <GridColumn
                className={headlineGroupStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 10,
                    large: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 2,
                    large: 3,
                }}
            >
                {props.Eyebrow && (
                    <Flag
                        className={eyebrownStyles}
                        fontSize="LabelLarge"
                        tag="div"
                    >
                        {props.Eyebrow}
                    </Flag>
                )}

                <Text
                    fontSize="HeadlineDefault"
                    marginBottom="LongformHeadlineMarginBottom"
                    tag="h1"
                    textAlign="center"
                >
                    {props.Headline}
                </Text>
            </GridColumn>

            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                    large: 6,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                    large: 4,
                }}
            >
                {props.Updated_Date && (
                    <Text
                        fontSize="LongformTextDefault"
                        fontWeight="medium"
                        marginBottom="LongformHeadlineSmall"
                        tag="time"
                        dateTime={props.Updated_Date}
                    >
                        {`Last modified ${formatDate(props.Updated_Date)}`}
                    </Text>
                )}

                <RichText config="Longform">{props.Page_Body}</RichText>
            </GridColumn>
        </GridContainer>
    );
};
