/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyProductFeature } from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";
import { VerticalLegacyFeatureItem } from "@/ui/molecules/vertical_legacy_feature_item";
import { HorizontalLegacyFeature } from "@/ui/organisms/horizontal_legacy_feature";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface LegacyFeatureProps extends StrapiLegacyProductFeature {
    className?: SerializedStyles;
}

export const LegacyFeature: FunctionComponent<LegacyFeatureProps> = (props) => {
    /**
     * Styles
     */
    const ctaContainerStyles = css({
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: "max-content",
    });

    const containerStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionMargin),
    );

    const verticalFeaturesContainerStyles = css(
        {
            display: "flex",
            flexWrap: "wrap",
        },
        buildStylesByBreakpoint("flexDirection", {
            extraSmall: "column",
            medium: "row",
        }),
        buildStylesByBreakpoint(
            "gap",
            spacingSets.LegacyFeatureVerticalItemsContainer,
        ),
    );

    const verticalFeatureStyles = (parentArrayLength: number) => {
        if (parentArrayLength === 4) {
            return buildStylesByBreakpoint("flex", {
                extraSmall: "1 1 0",
                medium: "1 0 45%",
            });
        }

        return css({
            flex: "1 1 0",
        });
    };

    const heroStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeaturesHeroText),
    );

    const headlineContainerStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const eyebrowStyles = css({
        marginBottom: Spacing["spacing-4"],
    });

    /**
     * Rendering
     */

    const renderHero = () => {
        return (
            <SubgridContainer
                className={heroStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                }}
            >
                <GridColumn className={headlineContainerStyles}>
                    {props.Label && (
                        <Flag className={eyebrowStyles} fontSize="LabelLarge">
                            {props.Label}
                        </Flag>
                    )}

                    {props.Headline && (
                        <Text
                            fontSize="LegacyFeaturesHeroHeadline"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>
                    )}
                </GridColumn>

                {props.Description && (
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 6,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            medium: 2,
                        }}
                    >
                        <RichText config="LegacyFeatureHero">
                            {props.Description}
                        </RichText>
                    </GridColumn>
                )}
            </SubgridContainer>
        );
    };

    const renderHorizontalFeatures = () => {
        const castedFeatures = props.Features!;

        return castedFeatures.map((feature, index) => (
            <HorizontalLegacyFeature
                {...feature}
                key={`legacy-horizontal-feature-item::${feature.Headline}-${index}`}
                orientation={index % 2 === 0 ? "default" : "reversed"}
            />
        ));
    };

    const renderVerticalFeatures = () => {
        const castedFeatures = props.Features!;

        const getColumnStartByFeaturesLength = (): GridColumnCount => {
            switch (castedFeatures.length) {
                case 1:
                    return {
                        extraSmall: 1,
                        medium: 2,
                        large: 3,
                    };
                case 3:
                    return 1;
                default:
                    return {
                        extraSmall: 1,
                        large: 2,
                    };
            }
        };

        const getColumnSpanByFeaturesLength = (): GridColumnSpan => {
            switch (castedFeatures.length) {
                case 1:
                    return {
                        extraSmall: "full",
                        medium: 10,
                        large: 8,
                    };
                case 3:
                    return "full";
                default:
                    return {
                        extraSmall: "full",
                        large: 10,
                    };
            }
        };

        return (
            <GridColumn
                className={verticalFeaturesContainerStyles}
                columnSpan={getColumnSpanByFeaturesLength()}
                columnStart={getColumnStartByFeaturesLength()}
            >
                {castedFeatures.map((feature, index, arr) => (
                    <VerticalLegacyFeatureItem
                        {...feature}
                        className={verticalFeatureStyles(arr.length)}
                        key={`legacy-vertical-feature-item::${feature.Headline}-${index}`}
                    />
                ))}
            </GridColumn>
        );
    };

    return (
        <GridContainer className={containerStyles}>
            {(props.Headline || props.Label) && renderHero()}

            {props.Features &&
                (props.Features_Orientation === "Horizontal"
                    ? renderHorizontalFeatures()
                    : renderVerticalFeatures())}

            {props.CTA && (
                <GridColumn className={ctaContainerStyles}>
                    <Button
                        fontSize="CtaDefault"
                        href={props.CTA.URL}
                        variant="secondary"
                    >
                        {props.CTA.Text}
                    </Button>
                </GridColumn>
            )}
        </GridContainer>
    );
};
