/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { FunctionComponent, useRef, useState } from "react";

import { StrapiFAQItem } from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { ButtonVariantKeys } from "@/tokens/configs/button_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { Icon } from "@/ui/atoms/icon";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";

import { useFaqExpansionAnimation } from "@/util/animation_hooks/faq_animations";
import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

import { RichText } from "./rich_text";

export const FaqItem: FunctionComponent<StrapiFAQItem> = (
    props: StrapiFAQItem,
) => {
    /**
     * Theme
     */
    const theme = useTypedTheme();

    /**
     * Refs
     */
    const answerContainerRef = useRef(null);
    const iconRef = useRef(null);

    /**
     * State Management
     */
    const [isExpanded, setIsExpanded] = useState(false);

    /**
     * Hooks
     */
    useFaqExpansionAnimation(answerContainerRef, iconRef, isExpanded);

    /**
     * Interactivity
     */
    const handleClick = () => {
        setIsExpanded(!isExpanded);
    };

    /**
     * Styles
     */
    const faqItemStyles = css(
        buildStylesByBreakpoint("paddingBottom", {
            extraSmall: Spacing["spacing-12"],
            large: Spacing["spacing-18"],
        }),
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-6"],
            large: Spacing["spacing-8"],
        }),
        {
            borderBottom: `1px solid ${Colors[theme.ui.uiBorder]}`,
        },
    );

    const buttonStyles = css(
        {
            appearance: "none",
            background: "none",
            border: "none",
            cursor: "pointer",
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            padding: 0,
            pointerEvents: "none", // note - I believe this prevents hover effect from firing
            width: "100%",
        },
        {
            "& >*": {
                pointerEvents: "auto",
            },
        },
    );

    /**
     * Rendering
     */
    const renderAnswer = () => {
        return (
            <GridColumn
                className={css({
                    height: 0,
                    overflow: "hidden",
                })}
                columnSpan={{
                    extraSmall: 6,
                    medium: 7,
                    large: 6,
                }}
                ref={answerContainerRef}
            >
                <RichText config="Longform">{props.Answer}</RichText>

                {props.CTA && (
                    <Button
                        className={css({ width: "fit-content" })}
                        fontSize="FaqItem"
                        href={props.CTA.URL}
                        marginTop="Default"
                        variant={
                            props.CTA.Appearance.toLowerCase() as
                                | ButtonVariantKeys
                                | undefined
                        }
                    >
                        {props.CTA.Text}
                    </Button>
                )}
            </GridColumn>
        );
    };

    return (
        <SubgridContainer
            className={faqItemStyles}
            columnSpan={{
                extraSmall: 6,
                medium: 10,
                large: 8,
            }}
            columnStart={{
                extraSmall: 1,
                medium: 2,
                large: 3,
            }}
            tag="li"
        >
            <GridColumn
                className={buttonStyles}
                columnSpan={{
                    extraSmall: 6,
                    medium: 12,
                }}
                tag="button"
                onClick={handleClick}
            >
                <Text
                    className={css({
                        marginBottom: Spacing["spacing-2"],
                    })}
                    fontSize="FaqItem"
                    fontWeight="medium"
                    tag="h2"
                    textAlign="left"
                >
                    {props.Question}
                </Text>

                <Icon
                    color="lighten-90"
                    fontSize="FaqItem"
                    ref={iconRef}
                    slug="Plus"
                />
            </GridColumn>

            {renderAnswer()}
        </SubgridContainer>
    );
};
