export const formatDate = (dateString: Date | string | undefined) => {
    if (!dateString) return null;

    let date = new Date(dateString);

    /*
     * Note: We're checking to see if the date is in "yyyy-MM-dd" format.
     * Javascript Date() parses these in UTC and can incorrectly display the date
     * as a day earlier than expected.
     * Reference: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
     */

    const isDateYearMonthDate = (dateString: string) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(dateString);
    };

    if (typeof dateString === "string" && isDateYearMonthDate(dateString)) {
        date = new Date(dateString.replace(/-/g, "/").replace(/T.+/, ""));
    }

    return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
};
