import * as _ from "lodash-es";
import type {
    GetStaticPaths,
    GetStaticPropsContext,
    InferGetStaticPropsType,
    NextPage,
    PreviewData,
} from "next";
import { ParsedUrlQuery } from "querystring";

import type {
    StrapiContactFormPage,
    StrapiGeneralTextPage,
    StrapiHomePageExperiment,
    StrapiLegacyPreFooter,
    StrapiProductFeaturePage,
} from "@/types/strapi";

import { ContactFormPageTemplate } from "@/ui/templates/contact_form_page";
import { GeneralTextPageTemplate } from "@/ui/templates/general_text_page";
import { HomePageTemplate } from "@/ui/templates/home_page";
import { ProductPageTemplate } from "@/ui/templates/product_page";

import { findSlugCollectionByPageSlug } from "@/util/cms_util";
import { generatePageLevelProps } from "@/util/data_util";
import { getHubSpotForm } from "@/util/form_util";
import {
    generateLegacyPreFooterData,
    generatePreFooterData,
    getProductFeatureBackgroundMedia,
} from "@/util/strapi_data_util";
import {
    getDynamicPageSlugs,
    getGlobals,
    getHomePageExperiment,
    getSlugPageById,
} from "@/util/strapi_util";

export const getStaticProps = async ({
    params,
    preview: previewMode,
}: GetStaticPropsContext<ParsedUrlQuery, PreviewData>) => {
    const slug = params?.slug;

    if (typeof slug !== "string") {
        return { notFound: true };
    }

    const collection = await findSlugCollectionByPageSlug(slug);

    if (!collection) {
        return { notFound: true };
    }

    const globalsData = await getGlobals(previewMode);

    if (collection.contentType === "api::exp-home.exp-home") {
        const pageData = await getHomePageExperiment(slug, previewMode);
        return {
            props: {
                ...generatePageLevelProps({
                    path: `/${slug}`,
                    previewMode,
                    slug,
                }),
                ...generatePreFooterData(
                    globalsData,
                    pageData.PreFooter,
                    true,
                    pageData.Theme.Footer_Media,
                ),
                formData: null,
                globalsData,
                legacyGrid: false,
                pageData,
                renderContactSales: false,
                renderDemo: true,
                renderNewNavigationBar: true,
                renderNotifications: true,
                renderPostFooter: true,
            },
        };
    }

    const pageData = await getSlugPageById(slug, previewMode);

    if (!pageData) {
        return { notFound: true };
    }

    // Get form data if Form_Id is defined
    let formData = null;
    if (_.has(pageData, "Form_Id")) {
        // Note: We need to cast since slug.tsx can be many types
        formData = await getHubSpotForm(pageData.Form_Id as string);
    }

    return {
        props: {
            ...generatePageLevelProps({ path: `/${slug}`, previewMode, slug }),
            ...generateLegacyPreFooterData(
                globalsData,
                _.has(pageData, "Pre_Footer")
                    ? // Typecasting is necessary since there are multiple content types here
                      (pageData.Pre_Footer as StrapiLegacyPreFooter)
                    : undefined,
                pageData.contentType !== "pages-general-text",
                getProductFeatureBackgroundMedia(pageData),
            ),
            formData,
            globalsData,
            pageData,
            renderMinimalNav:
                _.has(pageData, "Minimal_Nav") &&
                (pageData.Minimal_Nav as boolean),
        },
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    const slugs = await getDynamicPageSlugs();

    return {
        fallback: false,
        paths: slugs.map((slug) => ({ params: { slug } })),
    };
};

type PageProps = InferGetStaticPropsType<typeof getStaticProps>;

const Page: NextPage<PageProps> = (props) => {
    /**
     * Elements
     */
    switch (props.pageData.contentType) {
        case "exp-homes":
            return (
                <HomePageTemplate
                    {...(props.pageData as StrapiHomePageExperiment)}
                />
            );
        case "pages-product-feature":
            return (
                <ProductPageTemplate
                    {...(props.pageData as StrapiProductFeaturePage)}
                />
            );
        case "pages-contact-form":
            return (
                <ContactFormPageTemplate
                    {...(props.pageData as StrapiContactFormPage)}
                    formData={props.formData}
                />
            );
        default:
            return (
                <GeneralTextPageTemplate
                    {...(props.pageData as StrapiGeneralTextPage)}
                />
            );
    }
};

export default Page;
