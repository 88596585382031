/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import {
    StrapiBenefitItem,
    StrapiIcon,
    StrapiLegacyBenefitsGrid,
} from "@/types/strapi";
import { GridColumnCount, GridColumnSpan } from "@/types/tokens/grid";
import { ColumnStandardCount } from "@/types/tokens/grid";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { WatchDemo } from "@/ui/molecules/watch_demo";

import { useTypedTheme } from "@/util/hooks/theme_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface LegacyBenefitsGridProps extends StrapiLegacyBenefitsGrid {
    className?: SerializedStyles;
    items: Array<StrapiBenefitItem>;
}

export const LegacyBenefitsGrid: FunctionComponent<LegacyBenefitsGridProps> = (
    props,
) => {
    /**
     * Globals
     */
    const theme = useTypedTheme();
    const isNested = [2, 4].includes(props.items.length);

    /**
     * Styles
     */
    const containerStyles = css({
        alignItems: "center",
        rowGap: Spacing["spacing-18"],
    });

    const heroStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeaturesHeroText),
    );

    const benefitItemContainerStyles = css({
        rowGap: Spacing["spacing-12"],
    });

    const benefitItemStyles = css({
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
    });

    const iconStyles = css({
        marginBottom: Spacing["spacing-3"],
    });

    const textContainerStyles = css(
        {
            display: "flex",
            flexDirection: "column",
            paddingTop: convertToRem(7), // One-off value to align to icon
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyBenefitItemText),
    );

    const ctaStyles = css({
        display: "inline-block",
    });

    /**
     * Rendering
     */
    const renderHero = () => {
        return (
            <SubgridContainer
                className={heroStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                }}
            >
                <GridColumn>
                    {props.Headline && (
                        <Text
                            fontSize="LegacyFeaturesHeroHeadline"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>
                    )}
                </GridColumn>

                {props.Subheadline && (
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 6,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            medium: 2,
                        }}
                    >
                        <Text
                            fontSize="LegacyFeaturesHeroSubheadline"
                            textAlign="center"
                        >
                            {props.Subheadline}
                        </Text>
                    </GridColumn>
                )}
            </SubgridContainer>
        );
    };

    const renderBenefitItem = (
        benefitItem: StrapiBenefitItem,
        index: number,
    ) => {
        const { Description, Headline, Icon: benefitIcon } = benefitItem;

        const largeColumnStartValue = (index % 3) * 4 + 1;

        return (
            <GridColumn
                className={benefitItemStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 4,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: largeColumnStartValue as ColumnStandardCount,
                }}
                key={`legacy-benefit-item::${Headline}::${index}`}
            >
                {Icon && (
                    <Icon
                        className={iconStyles}
                        color={theme.ui.uiInactive}
                        fontSize="LegacyBenefitItemDescription"
                        renderContainer={true}
                        slug={benefitIcon as StrapiIcon}
                    />
                )}

                <div css={textContainerStyles}>
                    {Headline && (
                        <Text
                            fontSize="LegacyBenefitItemHeadline"
                            fontWeight="medium"
                            tag="h3"
                        >
                            {Headline}
                        </Text>
                    )}

                    {Description && (
                        <Text
                            fontSize="LegacyBenefitItemDescription"
                            themeKey="textPrimary"
                        >
                            {Description}
                        </Text>
                    )}
                </div>
            </GridColumn>
        );
    };

    const renderBenefitItems = () => {
        const columnStart: GridColumnCount = isNested
            ? {
                  extraSmall: 1,
                  medium: 2,
                  large: 3,
              }
            : 1;

        const columnSpan: GridColumnSpan = isNested
            ? {
                  extraSmall: "full",
                  medium: 10,
                  large: 8,
              }
            : "full";

        return (
            <SubgridContainer
                className={benefitItemContainerStyles}
                columnSpan={columnSpan}
                columnStart={columnStart}
            >
                {props.items.map((_benefitItem, _index) =>
                    renderBenefitItem(_benefitItem, _index),
                )}
            </SubgridContainer>
        );
    };

    return (
        <GridContainer className={containerStyles}>
            {(props.Headline || props.Subheadline) && renderHero()}

            {renderBenefitItems()}

            {props.CTA && (
                <GridColumn>
                    <Button
                        className={ctaStyles}
                        fontSize="ContactCTA"
                        href={props.CTA.URL}
                        marginTop="ContactItemCTAMarginTop"
                        variant="secondary"
                    >
                        {props.CTA.Text}
                    </Button>
                </GridColumn>
            )}

            {props.Watch_Demo && (
                <GridColumn>
                    <WatchDemo isFullWidthCta={false} />
                </GridColumn>
            )}
        </GridContainer>
    );
};
