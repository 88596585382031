/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useRef, useState } from "react";

import { StrapiLegacyCarousel } from "@/types/strapi";

import { spacingSets } from "@/tokens/configs/spacing_config";
import { Spacing } from "@/tokens/spacing";

import { Divider } from "@/ui/atoms/divider";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Icon } from "@/ui/atoms/icon";
import { Media } from "@/ui/atoms/media";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";

import { useLegacyFeatureCarouselAnimation } from "@/util/animation_hooks/legacy_media_carousel_animations";
import { buildCustomAspectRatio } from "@/util/data_util";
import { useArrayRef } from "@/util/hooks/ref_hooks";
import { buildStylesByBreakpoint } from "@/util/style_util";

interface LegacyCarouselProps extends StrapiLegacyCarousel {
    className?: SerializedStyles;
}

export const LegacyCarousel: FunctionComponent<LegacyCarouselProps> = (
    props,
) => {
    /**
     * State
     */
    const [currentCarouselRowIndex, setCurrentCarouselRowIndex] =
        useState<number>(0);

    /**
     * Refs
     */
    const currentCarouselMediaRef = useRef(null);
    const currentMobileMediaRef = useRef(null);

    const [carouselMediaRef, setCarouselMediaRef] = useArrayRef();
    const [mobileMediaContainerRef, setMobileMediaContainerRef] = useArrayRef();

    /**
     * Hooks
     */
    useLegacyFeatureCarouselAnimation(
        carouselMediaRef,
        currentCarouselMediaRef,
        mobileMediaContainerRef,
        currentMobileMediaRef,
    );

    /**
     * Interactivity
     */
    const handleClick = (index: number) => {
        setCurrentCarouselRowIndex(index);
    };

    /**
     * Styles
     */
    const containerStyles = css(
        buildStylesByBreakpoint("rowGap", spacingSets.DefaultSectionMargin),
    );

    const heroStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeaturesHeroText),
    );

    const mobileCarouselListStyles = css({
        flexDirection: "column",
    });

    const buttonStyles = css({
        alignItems: "center",
        appearance: "none",
        background: "none",
        border: "none",
        cursor: "pointer",
        display: "flex",
        gap: Spacing["spacing-3"],
        padding: `${Spacing["spacing-3"]} ${Spacing["spacing-2"]}`,
        width: "100%",
    });

    const desktopCarouselMediaContainerStyles = css(
        {
            aspectRatio: buildCustomAspectRatio(
                props.Carousel_Rows[0].Media.Media.width,
                props.Carousel_Rows[0].Media.Media.height,
            ),
            overflow: "hidden",
            position: "relative",
            width: "100%",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "block",
        }),
    );

    const desktopCarouselMediaItemStyles = css({
        height: "100%",
        left: 0,
        objectFit: "cover",
        position: "absolute",
        top: 0,
        width: "100%",
    });

    const mobileAccordionContainerStyles = css(
        {
            height: 0,
            overflow: "hidden",
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "block",
            medium: "none",
        }),
    );

    const mobileMediaStyles = css(
        {
            paddingBottom: Spacing["spacing-5"],
            paddingTop: Spacing["spacing-4"],
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "block",
            medium: "none",
        }),
    );

    /**
     * Rendering
     */

    const renderHero = () => {
        return (
            <SubgridContainer
                className={heroStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                }}
            >
                {props.Headline && (
                    <GridColumn>
                        <Text
                            fontSize="LegacyFeaturesHeroHeadline"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>
                    </GridColumn>
                )}

                {props.Subheadline && (
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 6,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            medium: 2,
                        }}
                    >
                        <RichText config="LegacyFeatureHero">
                            {props.Subheadline}
                        </RichText>
                    </GridColumn>
                )}
            </SubgridContainer>
        );
    };

    const renderCarouselButtons = () => {
        return (
            <GridColumn
                className={mobileCarouselListStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 5,
                    large: 4,
                }}
                tag="ul"
            >
                {props.Carousel_Rows.reduce(
                    (acc: JSX.Element[], curr, index) => {
                        const element = (
                            <li key={`carousel-button::${curr.id}`}>
                                <button
                                    css={buttonStyles}
                                    onClick={() => handleClick(index)}
                                >
                                    {curr.Icon && (
                                        <Icon
                                            renderContainer
                                            color="lighten-80"
                                            fontSize="FaqItem"
                                            slug={curr.Icon}
                                        />
                                    )}

                                    <Text
                                        fontSize="FaqItem"
                                        textAlign="left"
                                        themeKey="headlinePrimary"
                                    >
                                        {curr.Title}
                                    </Text>
                                </button>

                                <div
                                    css={mobileAccordionContainerStyles}
                                    ref={
                                        index === currentCarouselRowIndex
                                            ? currentMobileMediaRef
                                            : setMobileMediaContainerRef
                                    }
                                >
                                    <div css={mobileMediaStyles}>
                                        <Media
                                            {...curr.Media}
                                            Aspect_Ratio={undefined}
                                        />
                                    </div>
                                </div>
                            </li>
                        );
                        if (index < props.Carousel_Rows.length - 1) {
                            return [
                                ...acc,
                                element,
                                <Divider
                                    key={`product-page::divider::${index}`}
                                />,
                            ];
                        } else {
                            return [...acc, element];
                        }
                    },
                    [] as JSX.Element[],
                )}
            </GridColumn>
        );
    };

    const renderDesktopCarouselList = () => {
        return (
            <GridColumn
                className={desktopCarouselMediaContainerStyles}
                columnSpan={7}
                columnStart={6}
            >
                {props.Carousel_Rows.reduce(
                    (acc: JSX.Element[], curr, index) => {
                        const element = (
                            <div
                                css={desktopCarouselMediaItemStyles}
                                id={`mobile-feature-carousel-item::${curr.id}`}
                                key={`mobile-feature-carousel-item::${curr.id}`}
                                ref={
                                    index === currentCarouselRowIndex
                                        ? currentCarouselMediaRef
                                        : setCarouselMediaRef
                                }
                            >
                                <Media
                                    {...curr.Media}
                                    Aspect_Ratio={undefined}
                                />
                            </div>
                        );

                        return [...acc, element];
                    },
                    [] as JSX.Element[],
                )}
            </GridColumn>
        );
    };

    return (
        <GridContainer className={containerStyles}>
            {(props.Headline || props.Subheadline) && renderHero()}

            {renderCarouselButtons()}

            {renderDesktopCarouselList()}
        </GridContainer>
    );
};
