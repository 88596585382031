/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { useGSAP } from "@gsap/react";
import { gsap } from "gsap";
import {
    FunctionComponent,
    MouseEventHandler,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";
import ReactPlayer from "react-player";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { Modal } from "@/ui/atoms/modal";

import { useRudderAnalyticsTrack } from "@/util/hooks/use_rudder_analytics";
import { convertToRem } from "@/util/ui_util";

interface WatchDemoProps {
    className?: SerializedStyles;
    isFullWidthCta?: boolean;
}

export const WatchDemo: FunctionComponent<WatchDemoProps> = ({
    isFullWidthCta = true,
    ...props
}) => {
    /**
     * Styles
     */
    const containerStyles = css(
        {
            display: "flex",
            justifyContent: "center",
            paddingBlock: Spacing["spacing-2"],
        },
        isFullWidthCta && {
            backgroundColor: Colors["lighten-5"],
            // Legacy gradient, matching production
            backgroundImage:
                "radial-gradient(26.28% 109.48% at 50% 50%, rgba(8, 104, 249, 0.15) 39.5%, rgba(8, 104, 249, 0) 80.59%), radial-gradient(34.44% 143.48% at 50% 50%, rgba(16, 224, 111, 0.6) 0%, rgba(16, 224, 111, 0.01) 100%)",
            borderRadius: BorderRadiuses.borderLarge,
        },
        props.className,
    );

    const buttonStyles = css({
        backdropFilter: "none",
        outline: "none",
    });

    /**
     * State Management
     */
    const [showVideoModal, setShowVideoModal] = useState(false);

    /**
     * Interactivity
     */
    const track = useRudderAnalyticsTrack();

    const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
        track({ Event: "Watch Demo Clicked" });
        setShowVideoModal(true);
    };

    const handleModalClose = useCallback(() => {
        setShowVideoModal(false);
    }, []);

    /**
     * Rendering
     */
    return (
        <div css={containerStyles}>
            <Button
                className={buttonStyles}
                fontSize="TextSmall"
                iconPlacement="action"
                iconSlug="Play"
                variant={isFullWidthCta ? "ghost" : "secondary"}
                onClick={handleClick}
            >
                Watch the demo
            </Button>

            {showVideoModal && <VideoModal onClose={handleModalClose} />}
        </div>
    );
};

type VideoModalProps = {
    onClose: () => void;
};

function VideoModal(props: VideoModalProps) {
    /**
     * Globals & Refs
     */
    const containerRef = useRef<HTMLDivElement>(null);

    /**
     * Styles
     */
    const containerStyles = css({
        aspectRatio: "16 / 9",
        borderRadius: BorderRadiuses.borderMedium,
        flexGrow: 1,
        maxWidth: convertToRem(960),
        opacity: 0,
        overflow: "hidden",
    });

    /**
     * Animations
     */
    const { contextSafe } = useGSAP();

    const handlePlayerReady = useMemo(
        () =>
            contextSafe(() => {
                gsap.to(containerRef.current, {
                    opacity: 1,
                });
            }),
        [contextSafe],
    );

    /**
     * TODO: Loading State
     */

    /**
     * Rendering
     */
    return (
        <Modal onClose={props.onClose}>
            <div css={containerStyles} ref={containerRef}>
                <ReactPlayer
                    controls
                    playsinline
                    height="100%"
                    url="https://www.youtube.com/watch?v=Ab7tRyN62yI"
                    width="100%"
                    onReady={handlePlayerReady}
                />
            </div>
        </Modal>
    );
}
