import { Expo, gsap } from "gsap";
import { useEffect, useState } from "react";

import { TypedRefObject } from "@/types/interactivity";

export function useFaqExpansionAnimation(
    answerContainerRef: TypedRefObject,
    iconRef: TypedRefObject,
    isExpanded: boolean,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(answerContainerRef.current, {
            height: 0,
            onComplete: () => {
                setDidInit(true);
            },
            opacity: 0,
        });
        gsap.set(iconRef.current, {
            onComplete: () => {
                setDidInit(true);
            },
            transform: "rotate(0)",
        });
    }, [answerContainerRef, iconRef]);

    useEffect(() => {
        if (didInit) {
            const _heightValue = isExpanded ? "auto" : 0;
            const _opacityValue = isExpanded ? 1 : 0;
            const _rotateValue = isExpanded ? 45 * 3 : 0;
            const _duration = 0.5;
            const _ease = Expo.easeInOut;

            gsap.to(answerContainerRef.current, {
                duration: _duration,
                ease: _ease,
                height: _heightValue,
                opacity: _opacityValue,
            });
            gsap.to(iconRef.current, {
                duration: _duration,
                ease: _ease,
                rotate: _rotateValue,
            });
        }
    }, [isExpanded, didInit, answerContainerRef, iconRef]);
}
