/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { HubSpotField, HubSpotFormFieldGroup } from "@/types/forms";

import { BorderRadiuses } from "@/tokens/border";
import { Colors } from "@/tokens/color";
import { Spacing } from "@/tokens/spacing";

import { Button } from "@/ui/atoms/button";
import { SelectInput } from "@/ui/atoms/select_input";
import { Text } from "@/ui/atoms/text";
import { TextInput } from "@/ui/atoms/text_input";

import {
    processHubspotFormDataOnSubmit,
    submitHubSpotForm,
} from "@/util/form_util";

interface FormProps {
    className?: SerializedStyles;
    formId: string;
    inputs: HubSpotFormFieldGroup[];
    portalId: string;
    submitText?: string;
}

export interface HubspotFormValues {
    [key: HubSpotField["name"]]: HubSpotField["type"];
}

export const ContactForm: FunctionComponent<FormProps> = ({
    submitText = "Submit",
    ...props
}: FormProps) => {
    /**
     * State management
     */
    const [btnText, setBtnText] = useState(submitText);

    const [successMessage, setSuccessMessage] = useState(
        "Thank you! Your submission has been received!",
    );

    const [errorMessage, setErrorMessage] = useState<string>();

    const [showForm, setShowForm] = useState(true);

    /**
     * Hooks
     */
    const {
        formState: { errors, isSubmitted, isSubmitting },
        getValues,
        handleSubmit,
        register,
        reset,
        setError,
    } = useForm<HubspotFormValues>({
        shouldFocusError: true,
    });

    useEffect(() => {
        setBtnText(isSubmitting ? "Please wait..." : submitText);
    }, [isSubmitting, submitText]);

    /**
     * Handlers
     */
    const onSubmit = async (data: HubspotFormValues) => {
        setErrorMessage(undefined);

        const { fields, userErrors } = processHubspotFormDataOnSubmit({
            data,
            inputs: props.inputs,
        });

        if (userErrors) {
            return userErrors.forEach((error) => {
                setError(error.key as string, { message: error.message });
            });
        }

        const { errors, inlineMessage } = await submitHubSpotForm(
            props.portalId,
            props.formId,
            {
                fields: fields,
                submittedAt: new Date().getTime().toString(),
            },
        );

        if (errors) {
            console.error(errors);
            setErrorMessage(errors[0].message);

            return;
        }

        if (inlineMessage) {
            setSuccessMessage(inlineMessage);
            reset();
            setShowForm(false);
        }
    };

    const handleBlur = () => {
        if (!isSubmitted) {
            return;
        }

        const { userErrors } = processHubspotFormDataOnSubmit({
            data: getValues(),
            inputs: props.inputs,
        });

        if (userErrors) {
            return userErrors.forEach((error) => {
                setError(error.key as string, { message: error.message });
            });
        }
    };

    /**
     * Styles
     */
    const successMessageStyle = css({
        backgroundColor: Colors["lighten-5"],
        backgroundImage: "radial-gradient(circle at 50% 100%,#10e06f1a,#0000)",
        borderRadius: BorderRadiuses.borderMedium,
        boxShadow: `inset 0 0 0 1px ${Colors["lighten-10"]}`,
        marginTop: Spacing["spacing-4"],
        padding: Spacing["spacing-3"],
    });

    const errorMessageStyle = css({
        marginTop: Spacing["spacing-3"],
    });

    /**
     * Rendering
     */
    return showForm ? (
        <form noValidate css={props.className} id={props.formId}>
            <fieldset>
                {props.inputs.map((_input) => {
                    const _field = _input.fields[0];

                    const _errorMessage = errors[_field.name]
                        ?.message as string;

                    return _field.fieldType === "select" ? (
                        <SelectInput
                            key={`input::${_field.name}`}
                            {..._field}
                            errorMessage={_errorMessage}
                            register={register}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <TextInput
                            {..._field}
                            errorMessage={_errorMessage}
                            key={`input::${_field.name}`}
                            labelClassName={css({
                                marginBottom: Spacing["spacing-6"],
                            })}
                            register={register}
                            onBlur={handleBlur}
                        />
                    );
                })}
            </fieldset>

            <Button
                isDisabled={isSubmitting}
                variant="primary"
                onClick={handleSubmit(onSubmit)}
            >
                {btnText}
            </Button>

            {errorMessage && (
                <Text
                    className={errorMessageStyle}
                    color="primary-red"
                    fontSize="TextSmall"
                    themeKey="textSecondary"
                >
                    {errorMessage}
                </Text>
            )}
        </form>
    ) : (
        <Text
            className={successMessageStyle}
            fontSize="TextSmall"
            textAlign="center"
            themeKey="textSecondary"
        >
            {successMessage}
        </Text>
    );
};
