import { Expo, gsap } from "gsap";
import * as _ from "lodash-es";
import { useEffect, useState } from "react";

import { TypedArrayRefObject, TypedRefObject } from "@/types/interactivity";

import { Colors } from "@/tokens/color";

import { getBreakpointFromMediaMatch } from "@/util/ui_util";

/**
 * Legacy hero lottie carousel animation
 */
export function useLegacyMediaCarouselAnimation(
    mediaContainerRef: TypedRefObject,
    captionsContainerRef: TypedRefObject,
    carouselButtonRefs: TypedArrayRefObject,
    carouselItemsLength: number,
    currentMediaCarouselIndex: number,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set([mediaContainerRef.current, captionsContainerRef.current], {
            onComplete: () => {
                setDidInit(true);
            },
            xPercent: 0,
        });
    }, [mediaContainerRef, captionsContainerRef]);

    useEffect(() => {
        if (didInit && carouselItemsLength > 0) {
            // Slide the graphic
            const _translateXPercentage =
                (currentMediaCarouselIndex / carouselItemsLength) * 100;

            gsap.to(mediaContainerRef.current, {
                ease: Expo.easeInOut,
                xPercent: `-${_translateXPercentage}`,
            });

            // If captions are defined, slide them too
            if (captionsContainerRef.current) {
                gsap.to(captionsContainerRef.current, {
                    ease: Expo.easeInOut,
                    xPercent: `-${_translateXPercentage}`,
                });
            }

            // Animate buttons
            gsap.to(carouselButtonRefs.current, {
                backgroundColor: (index) =>
                    index === currentMediaCarouselIndex
                        ? Colors["white"]
                        : Colors["lighten-60"],
                ease: Expo.easeInOut,
            });
        }
    }, [
        captionsContainerRef,
        carouselButtonRefs,
        carouselItemsLength,
        currentMediaCarouselIndex,
        didInit,
        mediaContainerRef,
    ]);
}

/**
 * Legacy feature carousel animation
 */
export function useLegacyFeatureCarouselAnimation(
    mediaObjectRefs: TypedArrayRefObject,
    currentCarouselMediaRef: TypedRefObject,
    mobileMediaContainerRef: TypedArrayRefObject,
    currentMobileMediaRef: TypedRefObject,
) {
    /**
     * State Management
     */
    const [didInit, setDidInit] = useState(false);
    const debounceTime = 150;

    /**
     * Effects
     */
    useEffect(() => {
        gsap.set(currentCarouselMediaRef.current, {
            autoAlpha: 1,
        });
        gsap.set(mediaObjectRefs.current, {
            autoAlpha: 0,
            onComplete: () => {
                setDidInit(true);
            },
        });
    }, [currentCarouselMediaRef, mediaObjectRefs]);

    useEffect(() => {
        const _calculateResponsiveCarouselAction = () => {
            const _duration = 0.2;
            const _mobileDuration = 0.35;
            const _ease = Expo.easeInOut;
            const _breakpoint = getBreakpointFromMediaMatch();

            if (["extraSmall", "small"].includes(_breakpoint)) {
                gsap.to(mobileMediaContainerRef.current, {
                    autoAlpha: 0,
                    duration: _mobileDuration,
                    ease: _ease,
                    height: 0,
                });
                gsap.to(currentMobileMediaRef.current, {
                    autoAlpha: 1,
                    duration: _mobileDuration,
                    ease: _ease,
                    height: "auto",
                });
            } else {
                gsap.to(mediaObjectRefs.current, {
                    autoAlpha: 0,
                    duration: _duration,
                    ease: _ease,
                });
                gsap.to(currentCarouselMediaRef.current, {
                    autoAlpha: 1,
                    delay: _duration,
                    duration: _duration,
                    ease: _ease,
                });
            }
        };

        const _debouncedCalculateLargestValues = _.debounce(
            _calculateResponsiveCarouselAction,
            debounceTime,
        );

        _calculateResponsiveCarouselAction();

        window.addEventListener("resize", _debouncedCalculateLargestValues);

        return () => {
            window.removeEventListener(
                "resize",
                _debouncedCalculateLargestValues,
            );
        };
    }, [
        mobileMediaContainerRef.current,
        currentCarouselMediaRef.current,
        currentMobileMediaRef.current,
        didInit,
        mediaObjectRefs,
    ]);
}
