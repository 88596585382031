import { css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiTextFeature } from "@/types/strapi";

import { SpacingStyleKey } from "@/tokens/configs/spacing_config";

import { Button } from "@/ui/atoms/button";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Text } from "@/ui/atoms/text";

interface TextFeatureProps extends StrapiTextFeature {
    marginTop?: SpacingStyleKey;
}

export const TextFeature: FunctionComponent<TextFeatureProps> = ({
    marginTop = "TextFeatureDefaultBlock",
    ...props
}) => {
    /**
     * Styles
     */
    const privacyTextContainerStyles = css({
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
    });

    /**
     * Rendering
     */
    return (
        <GridContainer marginTop={marginTop}>
            {props.Headline && (
                <Text
                    columnSpan={{ extraSmall: 6, medium: 6, large: 5 }}
                    columnStart={{ extraSmall: 1, medium: 2 }}
                    fontSize="LegacyFeaturesHeroHeadline"
                    marginBottom="TextFeatureHeadlineSecondaryMargin"
                    tag="h2"
                >
                    {props.Headline}
                </Text>
            )}

            <GridColumn
                className={privacyTextContainerStyles}
                columnSpan={{ extraSmall: 6, medium: 4 }}
                columnStart={{ extraSmall: 1, medium: 8 }}
            >
                {props.Description && <Text>{props.Description}</Text>}

                {props.CTA && (
                    <Button
                        href={props.CTA.URL}
                        marginTop="TextFeatureCTAButtonMargin"
                        variant="secondary"
                    >
                        {props.CTA.Text}
                    </Button>
                )}
            </GridColumn>
        </GridContainer>
    );
};

export default TextFeature;
