/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiLegacyBigTileGrid, StrapiMediaPrimitive } from "@/types/strapi";

import { Colors } from "@/tokens/color";
import { spacingSets } from "@/tokens/configs/spacing_config";
import { ColumnGaps, Spacing } from "@/tokens/spacing";

import { Flag } from "@/ui/atoms/flag";
import { GridColumn } from "@/ui/atoms/grid_column";
import { GridContainer } from "@/ui/atoms/grid_container";
import { Media } from "@/ui/atoms/media";
import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { Text } from "@/ui/atoms/text";
import { RichText } from "@/ui/molecules/rich_text";

import { buildStylesByBreakpoint } from "@/util/style_util";
import { convertToRem } from "@/util/ui_util";

interface LegacyBigTileGridProps extends StrapiLegacyBigTileGrid {
    className?: SerializedStyles;
}

export const LegacyBigTileGrid: FunctionComponent<LegacyBigTileGridProps> = (
    props,
) => {
    /**
     * Styles
     */
    const containerStyles = css({
        alignItems: "center",
        rowGap: Spacing["spacing-18"],
    });

    const heroStyles = css(
        {
            alignItems: "center",
        },
        buildStylesByBreakpoint("gap", spacingSets.LegacyFeaturesHeroText),
    );

    const tilesContainerStyles = css(
        {
            position: "relative",
        },
        buildStylesByBreakpoint("rowGap", ColumnGaps),
    );

    const finalTileStyles = css(
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "block",
        }),
    );

    const bigLabelStyles = css(
        {
            background: Colors["linear-lighten-10-to-transparent"],
            backgroundColor: Colors["black"],
            borderRadius: convertToRem(16),
            left: "50%",
            letterSpacing: "-.015em",
            padding: Spacing["spacing-8"],
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "max-content",
            zIndex: 2,
        },
        buildStylesByBreakpoint("display", {
            extraSmall: "none",
            medium: "block",
        }),
    );

    const headlineContainerStyles = css({
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
    });

    const eyebrowStyles = css({
        marginBottom: Spacing["spacing-4"],
    });

    /**
     * Rendering
     */

    const renderHero = () => {
        return (
            <SubgridContainer
                className={heroStyles}
                columnSpan={{
                    extraSmall: "full",
                    medium: 8,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: 3,
                }}
            >
                <GridColumn className={headlineContainerStyles}>
                    {props.Label && (
                        <Flag className={eyebrowStyles} fontSize="LabelLarge">
                            {props.Label}
                        </Flag>
                    )}

                    {props.Headline && (
                        <Text
                            fontSize="LegacyFeaturesHeroHeadline"
                            tag="h2"
                            textAlign="center"
                        >
                            {props.Headline}
                        </Text>
                    )}
                </GridColumn>

                {props.Description && (
                    <GridColumn
                        columnSpan={{
                            extraSmall: "full",
                            medium: 6,
                        }}
                        columnStart={{
                            extraSmall: 1,
                            medium: 2,
                        }}
                    >
                        <RichText config="LegacyFeatureHero">
                            {props.Description}
                        </RichText>
                    </GridColumn>
                )}
            </SubgridContainer>
        );
    };

    const renderTile = (media: StrapiMediaPrimitive, index: number) => {
        const isEven = index % 2 === 0;
        const isLast = index === 3;
        return (
            <GridColumn
                columnSpan={{
                    extraSmall: "full",
                    medium: 6,
                }}
                columnStart={{
                    extraSmall: 1,
                    medium: isEven ? 1 : 7,
                }}
                {...(isLast && { className: finalTileStyles })}
                key={`legacy-big-tile-grid::tile::${index}`}
            >
                <Media {...media} />
            </GridColumn>
        );
    };

    const renderTiles = () => {
        return props.Media ? (
            <SubgridContainer className={tilesContainerStyles}>
                {props.Media.map((media, index) => {
                    return renderTile(media, index);
                })}

                <Text
                    className={bigLabelStyles}
                    fontSize="LegacyBigTileGridLabel"
                >
                    {props.TagLine ?? "Made with Warp"}
                </Text>
            </SubgridContainer>
        ) : null;
    };

    return (
        <GridContainer className={containerStyles}>
            {(props.Headline || props.Label || props.Description) &&
                renderHero()}

            {renderTiles()}
        </GridContainer>
    );
};
