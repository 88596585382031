/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from "@emotion/react";
import { FunctionComponent } from "react";

import { StrapiFAQItem } from "@/types/strapi";

import { Spacing } from "@/tokens/spacing";

import { SubgridContainer } from "@/ui/atoms/subgrid_container";
import { FaqItem } from "@/ui/molecules/faq_item";

import { buildStylesByBreakpoint } from "@/util/style_util";

interface FaqItemProps {
    className?: SerializedStyles;
    faqItems: StrapiFAQItem[];
}

export const FaqList: FunctionComponent<FaqItemProps> = (props) => {
    /**
     * Styles
     */
    const faqListStyles = css(
        buildStylesByBreakpoint("marginTop", {
            extraSmall: Spacing["spacing-12"],
            large: Spacing["spacing-18"],
        }),
    );

    /**
     * Elements
     */
    return (
        <SubgridContainer className={faqListStyles} tag="ul">
            {props.faqItems.map((_item) => (
                <FaqItem key={`faq-list::item::${_item.id}`} {..._item} />
            ))}
        </SubgridContainer>
    );
};
